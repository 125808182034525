<template>
  <div>
    <staff-culpabilities-filter  @active-change="filterActive = $event" :filter-model="filterModel" />
    <staff-culpabilities-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import StaffCulpabilitiesFilter from '@/views/Nsi/StaffCulpabilities/StaffCulpabilitiesFilter';
import StaffCulpabilitiesTable from '@/views/Nsi/StaffCulpabilities/StaffCulpabilitiesTable';

export default {
  name: 'StaffCulpabilities',
  components: { StaffCulpabilitiesTable, StaffCulpabilitiesFilter },
  data() {
    return {
      filterModel: {
        nameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
